import { SocketIoConfig } from "ngx-socket-io";

/**
 * プロジェクトタイトル
 */
export const TITLE = {
  // タイトルバー
  TITLE_BAR: "情報系荷物運搬便システム",
  // タイトルフッダー
  TITLE_FOOTER: "",
  // 開設日
  OPENING_DATE:
    "Copyright © 2020 Cabinet Office, Government Of Japan. All Rights Reserved.",
};

/**
 * 本番ドメイン
 */
export const PROD_DOMAIN = "prod-info-transport.net";

/**
 * domain test
 */
export const TEST_DOMAIN = "d1aejq8htq0ko3.cloudfront.net";

/**
 * domain stagging
 */
 export const STAGING_DOMAIN = "stg-info-transport.net";

/**
 * domain demo
 */
 export const DEMO_DOMAIN = "d3ltb8soa0dsdy.cloudfront.net";

/**
 * domain tpdev
 */
 export const TPDEV_DOMAIN = "dckfkbqvu7bq4.cloudfront.net";

/**
 * 非ログイン用APIキー
 */
// export const API_KEY = "e33f5421bc3645be8a0dece6689fac2f";

/**
 * auth0設定情報
 */
export const auth0_dev = {
  production: false,
  AUTH0_DOMAIN: "tpn-hsj-dev.au.auth0.com", // ApplicationsのDomain(auth0情報)
  AUTH0_CLIENT_ID: "pylb31T6omi1NbJlkpuo2ZIHdRhQf1c4", // ApplicationsのClient ID(auth0情報)
  AUDIENCE: "https://aws-jwt-authorizer", // APIsのAPI Audience(auth0情報)
  API_URL: "https://*", // トークン付与対象APIエンドポイント
};

/**
 * auth0設定情報(本番)
 */
export const auth0_test = {
  production: false,
  AUTH0_DOMAIN: "tpn-hsj-dev.au.auth0.com", // ApplicationsのDomain(auth0情報)
  AUTH0_CLIENT_ID: "pylb31T6omi1NbJlkpuo2ZIHdRhQf1c4", // ApplicationsのClient ID(auth0情報)
  AUDIENCE: "https://aws-jwt-authorizer", // APIsのAPI Audience(auth0情報)
  API_URL: "https://*", // トークン付与対象APIエンドポイント
};

/**
 * auth0設定情報(本番)
 */
export const auth0_prod = {
  production: false,
  AUTH0_DOMAIN: "xxxxx", // ApplicationsのDomain(auth0情報)
  AUTH0_CLIENT_ID: "xxxxx", // ApplicationsのClient ID(auth0情報)
  AUDIENCE: "xxxxx", // APIsのAPI Audience(auth0情報)
  API_URL: "https://*", // トークン付与対象APIエンドポイント
};

/**
 * auth0設定情報(ステージング)
 */
 export const auth0_stagging = {
  production: false,
  AUTH0_DOMAIN: "tpn-hsj-dev.au.auth0.com", // ApplicationsのDomain(auth0情報)
  AUTH0_CLIENT_ID: "pylb31T6omi1NbJlkpuo2ZIHdRhQf1c4", // ApplicationsのClient ID(auth0情報)
  AUDIENCE: "https://aws-jwt-authorizer", // APIsのAPI Audience(auth0情報)
  API_URL: "https://*", // トークン付与対象APIエンドポイント
};

/**
 * auth0設定情報(本番)
 */
 export const auth0_demo = {
  production: false,
  AUTH0_DOMAIN: "tpn-hsj-dev.au.auth0.com", // ApplicationsのDomain(auth0情報)
  AUTH0_CLIENT_ID: "pylb31T6omi1NbJlkpuo2ZIHdRhQf1c4", // ApplicationsのClient ID(auth0情報)
  AUDIENCE: "https://aws-jwt-authorizer", // APIsのAPI Audience(auth0情報)
  API_URL: "https://*", // トークン付与対象APIエンドポイント
};

/**
 * auth0設定情報(本番)
 */
 export const auth0_tpdev = {
  production: false,
  AUTH0_DOMAIN: "tpn-hsj-dev.au.auth0.com", // ApplicationsのDomain(auth0情報)
  AUTH0_CLIENT_ID: "pylb31T6omi1NbJlkpuo2ZIHdRhQf1c4", // ApplicationsのClient ID(auth0情報)
  AUDIENCE: "https://aws-jwt-authorizer", // APIsのAPI Audience(auth0情報)
  API_URL: "https://*", // トークン付与対象APIエンドポイント
};
/**
 * 国際化（i18n）設定
 */
export const environment_i18n = {
  production: false,
  defaultLang: "ja",
};

/**
 * セッションキー
 */
export const SESSION_KEY = {
  // ログインユーザ情報
  loginUserInformation: "loginUserInformation",

  // TODO ログアウト時のメッセージコード格納に使用　※auth0が間に入り、いいパラメータ受け渡し方法が見つかるまで使用
  // ログインアウト時メッセージコード
  loginOutMessageCode: "loginOutMessageCode",
};

export const OPEN_LOGIC1_DEV_DOMAIN = "https://h3an7fve5g.execute-api.ap-northeast-1.amazonaws.com/dev/";
export const CLOSE_LOGIC1_DEV_DOMAIN = "https://xoa6xpt5kk.execute-api.ap-northeast-1.amazonaws.com/dev/";
export const CLOSE_LOGIC2_DEV_DOMAIN = "https://cslsjj8td4.execute-api.ap-northeast-1.amazonaws.com/dev/";

export const OPEN_LOGIC1_TEST_DOMAIN = "https://9x8fi91vd4.execute-api.ap-northeast-1.amazonaws.com/test/";
export const CLOSE_LOGIC1_TEST_DOMAIN = "https://xxltswd6hd.execute-api.ap-northeast-1.amazonaws.com/test/";
export const CLOSE_LOGIC2_TEST_DOMAIN = "https://5gmmhv3a7f.execute-api.ap-northeast-1.amazonaws.com/test/";

export const OPEN_LOGIC1_STAGING_DOMAIN = "https://uruzr2hh08.execute-api.ap-northeast-1.amazonaws.com/staging/";
export const CLOSE_LOGIC1_STAGING_DOMAIN = "https://jsr8dn71sl.execute-api.ap-northeast-1.amazonaws.com/staging/";
export const CLOSE_LOGIC2_STAGING_DOMAIN = "https://cvpo0iqz4e.execute-api.ap-northeast-1.amazonaws.com/staging/";

export const OPEN_LOGIC1_PROD_DOMAIN = "https://cuhbwf0pf5.execute-api.ap-northeast-1.amazonaws.com/prod/";
export const CLOSE_LOGIC1_PROD_DOMAIN = "https://j28pb4hnk0.execute-api.ap-northeast-1.amazonaws.com/prod/";
export const CLOSE_LOGIC2_PROD_DOMAIN = "https://9yubq3jrd1.execute-api.ap-northeast-1.amazonaws.com/prod/";

export const OPEN_LOGIC1_DEMO_DOMAIN = "https://mj1gn7h438.execute-api.ap-northeast-1.amazonaws.com/demo/";
export const CLOSE_LOGIC1_DEMO_DOMAIN = "https://cnzujxetl4.execute-api.ap-northeast-1.amazonaws.com/demo/";
export const CLOSE_LOGIC2_DEMO_DOMAIN = "https://bzrk2cr7c5.execute-api.ap-northeast-1.amazonaws.com/demo/";

export const OPEN_LOGIC1_TPDEV_DOMAIN = "https://t1khtwjov4.execute-api.ap-northeast-1.amazonaws.com/tpdev/";
export const CLOSE_LOGIC1_TPDEV_DOMAIN = "https://9w14nxfo54.execute-api.ap-northeast-1.amazonaws.com/tpdev/";
export const CLOSE_LOGIC2_TPDEV_DOMAIN = "https://lh8gwp3sdh.execute-api.ap-northeast-1.amazonaws.com/tpdev/";

export const socketIoConfig: SocketIoConfig = {
  // url: "http://localhost:8080",
  url: "https://54.95.104.20:8080",
  options: {},
};
