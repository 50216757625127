import { NavigationEnd } from "@angular/router";
import { Component, Input, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { LoginService } from "src/app/shared/service/login.service";
import { LoadingState } from "../loading/loading-state";
import { CommonService } from "../../service/common.service";
import { SESSION_KEY } from "../../constant/session-constants";
import { CONSTANT, NO_LOGIN_SCREENS, ROLES } from "src/app/shared/constant/constant";
import { MESSAGE_CODE } from "src/app/shared/constant/message-constant";
import { NotificationDialogComponent } from "src/app/shared/html-parts/notification-dialog/notification-dialog.component";
import { DbOperationService } from "../../service/db-operation.service";

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"],
})

/**
 * ヘッダー
 */
export class HeaderComponent implements OnInit, OnDestroy {
  // ヘッダー判定フラグ(true:通常モード、false:メッセージモード)
  @Input() headerFlag: boolean;
  @Input() headerTitle: string;

  @ViewChild(NotificationDialogComponent)
  NotificationDialogComponent: NotificationDialogComponent;

  // 文字サイズ選択項目
  textSizeList: Object[] = [
    {
      name: "標準",
      code: "false",
    },
    {
      name: "拡大",
      code: "true",
    },
  ];

  // 文字サイズ
  textSize: string;
  // 課題ID
  agendaId: any;

  id: any;

  cities: any[] = [
    { name: "プロフィール", code: "Profile" },
    { name: "お問い合わせ", code: "Contact" },
    { name: "ログアウト", code: "Logout" },
  ];

  intervalUnread: NodeJS.Timeout;
  role: any[] = [{ name: "Teacher", code: "1" }];
  selectedCity: any;
  menuList: any[] = [];
  indexMenuActive = -1;
  department_type: any;
  lengthMenu: any;
  items: Object[] = [];
  activeItem2: any;
  department_id: any;
  unread_notification: number = 0;
  user: any = null;
  isOpenMoblieMenu: boolean[] = [false, false];
  linkPDF = 'https://transport-prod-file.s3.ap-northeast-1.amazonaws.com/common/pdf/%E3%80%90%E8%8D%B7%E7%89%A9%E9%81%8B%E6%90%AC%E4%BE%BF%E3%80%91%E3%82%B7%E3%82%B9%E3%83%86%E3%83%A0%E3%83%9E%E3%83%8B%E3%83%A5%E3%82%A2%E3%83%AB.pdf'

  constructor(
    private router: Router,
    private commonService: CommonService,
    private loginService: LoginService,
    private loadingState: LoadingState,
    public dbOperationService: DbOperationService,
    private route: ActivatedRoute
  ) { }
  ngOnDestroy(): void {
    clearInterval(this.intervalUnread);
  }

  ngOnInit() {
    this.router.events.subscribe((e) => {
      if (e instanceof NavigationEnd) {
        this.id = e.url;
        this.user = window.sessionStorage.getItem(SESSION_KEY.loginUserInformation);
        this.headerTitle = this.route.root.firstChild.snapshot.data['headerTitle'] ? this.route.root.firstChild.snapshot.data['headerTitle'] : this.route.root.firstChild.snapshot.data['breadcrumbTitle'];
        if (this.user) {
          this.user = JSON.parse(this.user);
          this.loadingState.loadSleepEnd(0.3);
          this.department_type = this.user?.department_type;
          this.department_id = this.user?.department_id;
          this.updateRightMenu();
        } else {
          if (!this.loginService.getAuth0LoginState()) {
            return
          }
          for (let index = 0; index < NO_LOGIN_SCREENS.length; index++) {
            const element = NO_LOGIN_SCREENS[index];
            if (e.url.replace('/', '').startsWith(element)) {
              return;
            }
          }
          this.loginService.getLoginUser().subscribe((response) => {
            this.loadingState.loadSleepEnd(0.3);

            if (this.commonService.checkNoneResponse(response)) {
              this.loginService.logout(MESSAGE_CODE.E90000);
              return;
            } else {
              window.sessionStorage.setItem(SESSION_KEY.loginUserInformation, JSON.stringify(response.body));
              this.user = JSON.stringify(response.body);
              this.department_type = response.body.department_type;
              this.department_id = response.body.department_id;
              this.updateRightMenu();
            }
          });
        }
      }
    });
  }

  public updateRightMenu(): void {
    switch (this.department_type) {
      case ROLES.SALES_1:
        this.items = [
          {
            label: '配送・集荷依頼',
            items: [
              {
                label: '配送依頼',
                icon: 'pi pi-search',
                url: '/pages/platform/c101?delivery_collect_kbn=0'
              },
              {
                label: '集荷依頼',
                icon: 'pi pi-search',
                url: '/pages/platform/c101?delivery_collect_kbn=1'
              },
              {
                label: '配送・集荷履歴',
                icon: 'pi pi-list',
                url: '/pages/platform/c201'
              }
            ]
          },
          {
            label: '管理',
            items: [
              {
                label: 'ユーザ',
                icon: 'pi pi-file',
                url: '/pages/platform/master/m301'
              },
            ]
          },
          {
            label: '',
            items: [
              {
                label: 'ログアウト',
                icon: 'pi pi-times-circle',
                command: () => {
                  this.loginService.logout();
                }
              }
            ]
          }
        ];
        break;
      case ROLES.TRANSIT_2:
        this.items = [
          {
            label: '配送・集荷依頼',
            items: [
              {
                label: '配送・集荷履歴',
                icon: 'pi pi-list',
                url: '/pages/platform/c201'

              }
            ]
          },
          {
            items: [
              {
                label: 'ログアウト',
                icon: 'pi pi-times-circle',
                command: () => {
                  this.loginService.logout();
                }
              }
            ]
          }
        ];
        break;
      case ROLES.COMMON_3:
        this.items = [
          {
            label: '配送・集荷依頼',
            items: [
              {
                label: '配送・集荷履歴',
                icon: 'pi pi-list',
                url: '/pages/platform/c201'
              }
            ]
          },
          {
            label: '管理',
            items: [
              {
                label: '資材',
                icon: 'pi pi-file',
                url: '/pages/platform/master/m101'
              },
              {
                label: '配送・集荷先',
                icon: 'pi pi-file',
                url: '/pages/platform/master/m201'
              },
              {
                label: 'ユーザ',
                icon: 'pi pi-file',
                url: '/pages/platform/master/m301'
              },
              {
                label: '部門',
                icon: 'pi pi-file',
                url: '/pages/platform/master/m401'
              },
              {
                label: 'お知らせ',
                icon: 'pi pi-file',
                url: '/pages/platform/master/m501'
              },
            ]
          },
          {
            label: '',
            items: [
              {
                label: 'ログアウト',
                icon: 'pi pi-times-circle',
                command: () => {
                  this.loginService.logout();
                }
              }
            ]
          }
        ];
        break;
      case ROLES.ADMIN_9:
        this.items = [
          {
            label: '配送・集荷依頼',
            items: [
              {
                label: '配送・集荷履歴',
                icon: 'pi pi-list',
                url: '/pages/platform/c201'
              }
            ]
          },
          {
            label: '管理',
            items: [
              {
                label: '資材',
                icon: 'pi pi-file',
                url: '/pages/platform/master/m101'
              },
              {
                label: '配送・集荷先',
                icon: 'pi pi-file',
                url: '/pages/platform/master/m201'
              },
              {
                label: 'ユーザ',
                icon: 'pi pi-file',
                url: '/pages/platform/master/m301'
              },
              {
                label: '部門',
                icon: 'pi pi-file',
                url: '/pages/platform/master/m401'
              },
              {
                label: 'お知らせ',
                icon: 'pi pi-file',
                url: '/pages/platform/master/m501'
              },
            ]
          },
          {
            label: '',
            items: [
              {
                label: 'ログアウト',
                icon: 'pi pi-times-circle',
                command: () => {
                  this.loginService.logout();
                }
              }
            ]
          }
        ];
        break;
      default:
        this.items = [
          {
            label: '',
            items: [
              {
                label: 'ログアウト',
                icon: 'pi pi-times-circle',
                command: () => {
                  this.loginService.logout();
                }
              }
            ]
          }
        ];
        break;
    }
  }

  public getLoginClassId(): string | number {
    const loginUser = JSON.parse(
      window.sessionStorage.getItem(SESSION_KEY.loginUserInformation)
    );

    if (!loginUser) {
      return CONSTANT.EMPTY_STRING;
    }

    return loginUser.department_type;
  }

  public checkShowMenu(): boolean {
    if (
      this.id &&
      (this.id.startsWith("/pages/platform/a201") ||
        this.id.startsWith("/pages/platform/a203") ||
        this.id.startsWith("/pages/platform/a210") ||
        this.id.startsWith("/pages/platform/a211") ||
        this.id.startsWith("/pages/platform/a212") ||
        this.id.startsWith("/pages/platform/a213") ||
        this.id.startsWith("/pages/platform/a203") ||
        this.id.startsWith("/pages/platform/a204") ||
        this.id.startsWith("/pages/platform/landing") ||
        this.id.startsWith("/pages/platform/contact") ||
        (this.id.startsWith("/pages/platform/c231") && !this.user) ||
        (this.id.startsWith("/pages/platform/c331") && !this.user))
    ) {
      return false;
    }
    return true;
  }

  public checkShowIconButton(): boolean {
    if (
      this.id &&
      (
        this.id.startsWith("/pages/platform/a212") ||
        this.id.startsWith("/pages/platform/a213") ||
        this.id.startsWith("/pages/platform/landing") ||
        this.id.startsWith("/pages/platform/contact"))
    ) {
      return false;
    }
    return true;
  }

  public checkLanding(): boolean {
    if (
      this.id &&
      (this.id.startsWith("/pages/platform/landing") ||
        this.id.startsWith("/pages/platform/contact"))
    ) {
      return true;
    } else if ((this.id?.startsWith("/pages/platform/c231") || this.id?.startsWith("/pages/platform/c331")) && !this.user) {
      return true;
    }
    return false;
  }

  public isA201() {
    return this.id?.startsWith("/pages/platform/a201") || this.id?.startsWith("/pages/platform/a203") || this.id?.startsWith("/pages/platform/a204")
  }

  public headerActive(): boolean {
    if (this.id && this.id.startsWith("/pages/platform/landing")) {
      return false;
    }
    return true;
  }
  openNotificationDialog(event: Event) {
    this.NotificationDialogComponent.openDialog(event);
  }
  login() {
    this.loginService.logout();
  }

  moves(path: string) {
    this.router.navigate([path]);
    this.isOpenMoblieMenu[0] = false;
    this.isOpenMoblieMenu[1] = false;
  }
  blurElement(el: HTMLElement) {
    el.blur();
  }

  getManualSchool() {
    window.open("https://xxxxx.pdf")
  }

  getManualSupport() {
    window.open("https://xxxxx.pdf")
  }
  public isPage(path: string) {
    const re = this.id?.startsWith(path);
    return re
  }

  onHome() {
    this.router.navigate(['top']);
  }

  openURI() {
    window.open(this.linkPDF, '_blank').focus();
  }
}
